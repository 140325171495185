import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let LawfulLogo = (props) => (
	<SvgIcon {...props} viewBox={"0 0 163 43"}>
		<title>Lawful</title>

		<g>
			<g>
				<polygon
					fill="#C79C69"
					points="24.21,21.51 24.21,17.59 1.4,17.59 1.4,15.09 29.04,15.09 29.04,11.17 1.4,11.17 1.4,1.5 
			41.4,1.5 41.4,41.5 21.49,41.5 21.49,23.95 17.57,23.95 17.57,41.5 14.7,41.5 14.7,23.95 10.78,23.95 10.78,41.5 7.91,41.5 
			7.91,23.95 3.99,23.95 3.99,41.5 1.4,41.5 1.4,21.51 		"
				/>
			</g>
			<g>
				<path
					fill="#222222"
					d="M69.88,35.64H49.92v-0.4c1.6,0,2.4-0.8,2.4-3.6v-20c0-2.8-0.8-3.6-2.4-3.6v-0.4h9.68v0.4
			c-1.6,0-3.2,0.8-3.2,3.6v23.48h7.08c4.4,0,6-4.4,6-6h0.4V35.64z"
				/>
				<path
					fill="#222222"
					d="M83.2,32.24c-1.04,2.12-2.92,3.8-6.28,3.8c-3.16,0-5.52-1.8-5.52-5.12c-0.04-3.4,3.24-4.8,7.8-5.88
			c2.84-0.68,3.96-1.92,4-3.8c-0.08-4.36-2.56-5.52-4.4-5.52c-3.48,0-4.96,5.08-6,7.64h-0.44l0.08-6.2c1.36-1,3.88-1.92,6.04-1.92
			c4.48,0,8.72,0.88,8.72,8.96v5.84c0,2.8,0.8,3.6,2.4,3.6v0.4l-6,2h-0.4V32.24z M83.2,23.88c-1.28,0.96-3.24,1.48-4.8,1.88
			c-2.48,0.64-3.12,2.52-3.12,4c0,2.48,1.6,4.16,3.84,4.12c2.4,0,3.4-1.16,4.08-2.72V23.88z"
				/>
				<path
					fill="#222222"
					d="M118,15.64v0.4c-1.6,0-3.8,1.76-4.64,4.6l-4.48,15.4h-0.48l-5.56-16.52L98,36.04h-0.48L92,19.64
			c-0.96-2.84-1.96-3.6-3.56-3.6v-0.4h9v0.4c-1.6,0-2.24,0.72-1.24,3.6l3.24,9.32l3.12-10.28c-0.8-2.04-1.32-2.64-2.04-2.64v-0.4
			h7.8v0.4c-1.6,0-2.24,0.72-1.24,3.6l3.24,9.32l2.48-8.32c0.84-2.84-0.12-4.6-1.72-4.6v-0.4H118z"
				/>
				<path
					fill="#222222"
					d="M118.48,35.24c1.6,0,2.4-0.8,2.4-3.6v-15.6h-2v-0.4h2v-1.2c0-3.44,0.28-7.2,5.72-7.2
			c1.76,0,3.2,0.92,4.56,1.92l0.08,6.2h-0.44c-2.08-6.4-3.08-7.84-5.04-7.6c-3.48,0.48-1.32,4.68-0.88,7.88h2.8v0.4h-2.8v15.6
			c0,2.8,1.6,3.6,3.2,3.6v0.4h-9.6V35.24z"
				/>
				<path
					fill="#222222"
					d="M145.44,33.24c-1.48,1.88-3.56,2.8-5.48,2.8c-3.6,0-6.04-2.08-6.04-7v-7.8c0-2.8-0.8-3.6-2.4-3.6v-0.4l6-2
			h0.4v14.6c0,4.6,2.32,4.96,3.48,4.96c1.6,0,2.92-0.8,4.04-2.2V21.24c0-2.8-0.8-3.6-2.4-3.6v-0.4l6-2h0.4v14.8
			c0,2.8,0.8,3.6,2.4,3.6v0.4l-6,2h-0.4V33.24z"
				/>
				<path
					fill="#222222"
					d="M152.8,35.24c1.6,0,2.4-0.8,2.4-3.6v-19.2c0-2.8-0.8-3.6-2.4-3.6v-0.4l6-2h0.4v25.2c0,2.8,0.8,3.6,2.4,3.6
			v0.4h-8.8V35.24z"
				/>
			</g>
		</g>
	</SvgIcon>
);

LawfulLogo = memo(LawfulLogo);
LawfulLogo.displayName = "LawfulLogo";
LawfulLogo.muiName = "LawfulLogo";

export default LawfulLogo;
